//#region Informations
/*
## Methods to create on component who calls this mixin

# onCodeDefault(modelSliced)
When the component emmmit 'not-bipping'

# onCodeDefault(onCodeSliced)
When the component emmmit 'bipping'
When bipping a code and it has a sliced settings

# filterBarcodeSettings(data, this.callbackBarcodeScanned)
When scanner is disabled and depending of another input
When this another input changed, you call this metod to get the sliced params to setting into model and use when you biping

- The second param is a callback function who will be called insade the mixin, but you need to instance it on component

# callbackInputBarcodeScanned()
When the barcode scanner is not biping, but you need a callback to to something
ex: call updateTags function

# setBarCodeRules({field: 'motorista', settingsBeeping: false})
you need call this function on MOUNTED, passing 'field' param when the input depending of another input
the second param you can use when needs to not show the BipingSettings icon on tooltip

*/
//#endregion

import {mapActions, mapGetters, mapState} from 'vuex'

const barCodeScannerMixin = {
  data() {
    return {
      //Barcode Scanner Settings
      barCodeSlice: null,
      storeId: null,
      storesToBarcodeSettings: [],
      code: null,
      itsBipping: false,

      barCodeModel: null,
      barCodeSliceModel: null,
      barCodeTarget: null,

      //Tooltip
      configBipingIcon: true,

      //Print
      printIcon: false,

      //Helpers
      barCodeInfoNeeded: false,
      barCodeInfoNeededField: '',
    }
  },
  computed: {
    ...mapState({
      zebraPrinter: state => state.printer.zebraPrinter,
      checkPrinter: state => state.printer.checkPrinter,
      printerConnected: state => state.printer.printerConnected,
      error: state => state.error.error,
      loading: (state) => state.loading.loading,
    }),
    ...mapGetters({
      bippingSensitivity: 'userAgent/bippingSensitivity',
    }),

    tooltip() {
      return [
        (this.configBipingIcon ? this.tooltipBeeping : null),
        (this.printIcon && this.zebraPrinter ? this.tooltipPrint : null),
        {label: 'Digite ou use o leitor de código de barras'},
      ]
    },

    tooltipBeeping() {
      return {
        label: 'Configurações de Bipagem',
        icon: 'settings',
        action: 'configBiping',
        disabled: this.settingsDisabled,
      }
    },

    tooltipPrint() {
      return {
        label: this.printerConnected 
          ? 'Sua impressora Zebra está conectada, clique para desconectar' 
          : 'Clique para conectar sua impressora Zebra',
        icon: 'print',
        action: 'print',
        active: this.printerConnected
      }
    },

    settingsDisabled() {
      return (
        this.barCodeModel === null || 
        this.barCodeModel === undefined || 
        this.barCodeModel && this.barCodeModel.length === 0
      )
    },
    currentProperties() {
      if (this.code !== null) {
        return {code: this.code}
      }
      return ''
    },

  },
  mounted() {
    this.initBarcodeScanner()
  },
  destroyed() {
    this.$barcodeScanner.destroy()
  },
  methods: {
    ...mapActions({
      setError: 'error/setError',
    }),

    //Init
    initBarcodeScanner() {
      this.$barcodeScanner.init(this.onBarcodeScanned)
      this.$barcodeScanner.setSensitivity(this.bippingSensitivity)
    },

    //Call this function if barCodeInfo to slice is needed
    setBarCodeRules({field, settingsBeeping}) {
      this.barCodeInfoNeeded = true

      if (field !== undefined) {
        this.barCodeInfoNeededField = field
      }

      if (settingsBeeping !== undefined) {
        this.configBipingIcon = !!settingsBeeping
      }

    },

    setPrinterRules() {
      this.printIcon = true
    },

    onInputBarcodeScanned(e) {
      if (!e || e.length === 0) return

      const itsArray = Array.isArray(e)
      const itsObject = typeof e === 'object'

      const code = itsArray || itsObject
        ? itsArray 
          ? e[e.length - 1].name 
          : e.name
        : e

      if (this.itsBipping) {
        this.onBarcodeScanned(code, true)
      } else {
        if (typeof this.callbackInputBarcodeScanned === 'function') {
          this.callbackInputBarcodeScanned(code)
        }
      }    
    },

    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return typeof(JSON.parse(str)) === 'object'
    },

    //On Scanned
    onBarcodeScanned(barcode, type) {
      if (this.isJsonString(barcode)) barcode = JSON.parse(barcode).id

      //Checkers
      const checker = this.onBarcodeScannedCheckers(barcode, type)
      if (checker) {
        this.itsBipping = false
        return
      }
      
      //Slice code
      this.barcodeSliceCode(barcode)

      //Reset
      this.itsBipping = false
      
      //Send
      if (this.barCodeTarget !== '') {
        //onCodeSliced callback function needed to be created on page who calls this mixin
        this.onCodeSliced(this.barCodeTarget)
      }

    },

    onBarcodeScannedCheckers(barcode, type) {
      
      //Check if printer component is on the page, and printer is not connected
      if (this.checkPrinter) {
        return true
      }

      //Check if its beeping and comming from input
      if (!this.itsBipping && type) {
        return true
      }

      //Check if barcode is empty
      if (barcode === '') {
        return true
      }

      //Check if user scanning on loading and block
      if (this.loading) {
        return true
      }

      if (this.barCodeInfoNeeded) {
        //Check if model store is null
        if (this.barCodeModel === null || this.barCodeModel.length === 0) {
          setTimeout(() => {
            this.setError(`Selecione o campo ${this.barCodeInfoNeededField} para continuar.`)
          }, 100)
          return true
        }
      }

      //Checkers if has Beeping Settings on page
      if (this.configBipingIcon) {
        this.onBarcodeScannedBeepingSettingsCheckers(barcode)
      }

    },

    onBarcodeScannedBeepingSettingsCheckers(barcode) {
      //Check if modal is open
      const modalIsOpen = this.$refs?.barcodeScannerSettings?.modal
      if (modalIsOpen) {
        this.code = barcode
        return true
      }

      //Checkers when need barCodeModel info
      if (this.barCodeInfoNeeded) {
        //Check if model store is null
        if (this.barCodeModel === null || this.barCodeModel.length === 0) {
          setTimeout(() => {
            this.setError(`Selecione o campo ${this.barCodeInfoNeededField} para continuar.`)
          }, 100)
          return true
        }

        //Check if model has info to slice
        const itsArray = Array.isArray(this.barCodeModel)
        let condition = null

        if (itsArray) {
          condition = !this.barCodeModel[0] && !this.barCodeModel[0].info && !this.barCodeModel[0].info.bar_code_slice
        } else {
          condition = !this.barCodeModel.info && !this.barCodeModel.info.bar_code_slice
        }
        
        if (condition) {
          return true
        } else {
          this.barCodeSliceModel = itsArray ? this.barCodeModel[0].info.bar_code_slice : this.barCodeModel.info.bar_code_slice
        }
      }
    },

    barcodeSliceCode(barcode) {
      //Slice code
      let s = this.barCodeSlice || this.barCodeSliceModel
      let b = null

      if (this.barCodeModel === null || this.barCodeModel.length === 0) {
        b = barcode
      } else {
        b = s
          ? barcode.slice(s[0], barcode.length - (s[1] ? s[1] : 0))
          : barcode
      }

      this.barCodeTarget = b
    },

    //Handlers
    previousBarcode() {
      return this.$barcodeScanner.getPreviousCode()
    },

    configBiping() {
      this.$refs?.barcodeScannerSettings?.open()
    },

    setBarcodeModel(model) {
      this.barCodeModel = model
    },

    //When user chose a Store
    filterBarcodeSettings(data, callback) {
      if (data) {
        //Set model on data
        this.setBarcodeModel(data)

        if (this.configBipingIcon) {
          this.checkStoreBarCodeSlice(data)
        }
      } else {
        this.setBarcodeModel(null)
      }

      if (callback !== undefined) {
        callback()
      }

    },

    updateStoresBipingSettings() {
      this.checkStoreBarCodeSlice(this.barCodeModel)
    },

    //Barcode Scanner Slice
    checkStoreBarCodeSlice(stores) {
      if (stores === null || stores === undefined || stores && stores.length === 0) {
        return
      }

      if(!Array.isArray(stores)) {
        stores = [stores]
      }

      stores.map((el) => {
        //Check if backend dont return Info object inside stores
        if (el.info === undefined) {
          el.info = {}
        }

        const hasCodeSettings = this.$refs?.barcodeScannerSettings?.getCodeLocalStorage(el)

        if (el.info == null) {
          return el
        }

        let elCodeSlice =
          el.info.bar_code_slice !== null &&
          el.info.bar_code_slice !== undefined
            ? el.info.bar_code_slice
            : [0, 0]

        if (hasCodeSettings !== undefined && hasCodeSettings !== null) {
          el.info.bar_code_slice = elCodeSlice
          el.info.bar_code_slice_ls = hasCodeSettings.codeSlice
          this.barCodeSlice = hasCodeSettings.codeSlice
        } else {
          el.info.bar_code_slice = elCodeSlice
          el.info.bar_code_slice_ls = elCodeSlice
          this.barCodeSlice = elCodeSlice
        }

        return el
      })

      this.storesToBarcodeSettings = stores
    },

    handlerSaveBarcodeScannerSetting(val) {
      this.barCodeSlice = val
    },

    handlerRestoreBarcodeScannerSetting(store) {
      const index = this.storesToBarcodeSettings.findIndex(
        (el) => el.id === store.id
      )
      if (index > -1) {
        this.storesToBarcodeSettings[
          index
        ].info.bar_code_slice_ls = this.storesToBarcodeSettings[
          index
        ].info.bar_code_slice
        this.$refs?.barcodeScannerSettings?.setStoreSelected(
          this.storesToBarcodeSettings[index]
        )
      }
      this.barCodeSlice = this.storesToBarcodeSettings[
        index
      ].info.bar_code_slice
    },

    handlerDeleteBarcodeScannerSetting(val) {
      this.storesToBarcodeSettings.map((el) => {
        el.info.bar_code_slice_ls = el.info.bar_code_slice
      })
      this.$refs?.barcodeScannerSettings?.setStoreSelected(
        this.storesToBarcodeSettings[this.storesToBarcodeSettings.length - 1]
      )
      this.barCodeSlice = val
    },
  },
}

export default barCodeScannerMixin
