<template>
  <main class="TransporterTransfer">
    <TheSubHeader
      icon="dashboard-package-request"
      title="Redespacho e transferência"
      page-title-tag="Transporter Transfer"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="1fr 1fr 1fr 120px"
    >
      <template #beforeButtons>
        <BaseButton
          icon="dashboard-package-request"
          label="Histórico de redespacho e transferência"
          @click="$router.push('/transporters/transferHistory')"
        />
      </template>
      <template #filter>
        <!-- Search -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pesquisar"
          label="Pesquisar"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Store -->
        <BaseTreeSelect
          v-model="filters.store_id"
          name="Loja"
          label="Loja"
          placeholder="Selecione"
          multiple
          :enable-paste="false"
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch(); filterBarcodeSettings($event)"
        />
        <!-- Transporter Origin -->
        <BaseTreeSelect
          v-model="filters.transportersOrigin"
          name="Transportadora Origem"
          label="Transportadora Origem"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag v-if="allTags.length" :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <section class="TransporterTransfer__orders">
      <div class="TransporterTransfer__ordersContent">
        <div class="TransporterTransfer__table">
          <DataTable
            v-model="form.orders"
            :header="transferTable.data.headers"
            :table="transferTable.data.content"
          />
          <Pagination v-if="transferTable.data.content.length" class="TransporterTransfer__table--pagination" @page="search()" @paginate="search()" />
          <!-- Add Form -->
          <div class="TransporterTransfer__table--confirm">
            <div class="TransporterTransfer__table--information">
              <p>{{ ordersSelectedText }}</p>
              <p v-if="transporterDestinyErrorText" class="TransporterTransfer__table--information--error">
                <b>{{ transporterDestinyErrorText }}</b>
              </p>
            </div>
            <DynamicForm
              ref="form"
              class="TransporterTransfer__form"
              :model="form"
              :blueprint="blueprint({transportersDestiny, disabledFields})"
              :endpoint-errors="endpointErrors"
              submit-type="json"
              @clearTransporterDestiny="form.transporterDestiny = null"
              @submit="onSubmit"
            />
          </div>
        </div>
      </div>
    </section>
    <Dialog
      v-if="showMissingOrders"
      :warning="missingOrdersText"
      :label-cancel="false"
      label-confirm="Confirmar"
      @confirm="removeMissingOrders"
    >
      <BaseList :list="missingOrders" :title="'Nome do Pedido'" :scrollbar="true" scrollbar-height="25vh" />
    </Dialog>
    <Dialog
      v-if="showOrdersWithTransporterDestinyError"
      :warning="ordersWithTransporterDestinyErrorText"
      label-cancel="Cancelar"
      label-confirm="Confirmar"
      @cancel="showOrdersWithTransporterDestinyError = false"
      @confirm="hideOrdersWithTransporterDestinyError"
    >
      <BaseList :list="ordersWithTransporterDestinyError" title="Número do Pedido" :scrollbar="true" scrollbar-height="25vh" />
    </Dialog>
    <Dialog
      v-if="showSuccessDialog"
      label-confirm="Confirmar"
      :warning="`${typeName.optionA} com sucesso`"
      label-cancel=""
      @confirm="confirmSuccess"
    />
    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { transporters } from '@/api'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination, DynamicForm, Dialog, BaseList, BarcodeScannerSettings, FiltersTag } from '@/components/molecules'
import { mapActions, mapState } from 'vuex'
import blueprint from './TransporterTransfer.blueprint'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'

export default {
  name: 'TransporterTransfer',
  components: {
    DataTable,
    Dialog,
    BaseButton,
    BaseList,
    BaseTreeSelect,
    DynamicForm,
    TheSubHeader,
    Pagination,
    BarcodeScannerSettings,
    FiltersTag
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      filters: {
        searchValue: '',
        store_id: [],
        transportersOrigin: [],
        query: []
      },
      allTags: [],
      form: {
        transporterDestiny: null,
        orders: [],
        type: null
      },
      endpointErrors: {},
      showMissingOrders: false,
      showOrdersWithTransporterDestinyError: false,
      ordersWithTransporterDestinyErrorText: '',
      missingOrders: [],
      missingOrdersText: '',
      showSuccessDialog: false
    }
  },
  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      transferTable: state => state.routes.ordersWithoutRoutes,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    typeName() {
      if (this.form?.type?.value === 'redespacho_agenciadora') {
        return {
          optionA: 'Redespacho realizado',
          optionB: 'no redespacho'
        }
      } else if (this.form?.type?.value === 'transferencia_interestadual') {
        return {
          optionA: 'Transferência realizada',
          optionB: 'na transferência'
        }
      } else {
        return null
      }
    },

    disabledFields() {
      return {
        type: !this.form.orders.length,
        transporterDestiny: !(!!this.form.orders.length && !!this.form.type),
        save: !this.form.transporterDestiny || !this.form.orders.length
          || !(this.form.orders.length > this.ordersWithTransporterDestinyError.length),
      }
    },

    ordersSelectedText() {
      let text
      let total = this.form.orders.length
      if (total > 0) {
        text = `${total} pedido${total === 1 ? '' : 's'} selecionado${total === 1 ? '' : 's'}`
      } else {
        text = `Selecione ${window.innerWidth < 960 ? 'abaixo' : 'acima'} os pedidos que serão redespachados ou transferidos para a transportadora desejada.`
      }
      return text
    },

    ordersWithTransporterDestinyError() {
      if (this.form.transporterDestiny?.id && this.form.orders.length) {
        return this.form.orders
          .filter((order) => (
            order.transporter === this.form.transporterDestiny.name
          ))
          .map((order) => order.order_number)
      }

      return []
    },

    transporterDestinyErrorText() {
      if(this.ordersWithTransporterDestinyError.length)
        return '- Alguns pedidos selecionados já pertencem a transportadora destino'

      return ''
    },

    transportersDestiny() {
      if (!this.filters.transportersOrigin.length) return this.transporters.filter(transporter => (
        transporter.type_name === this.form?.type?.value
      ))

      return this.transporters.filter(transporter => (
        !this.filters.transportersOrigin.some((transporterOrigin) => (
          transporterOrigin.name === transporter.name
        )).filter(transporter => (
          transporter.type_name === this.form?.type?.value
        ))
      ))
    },
  },

  mounted() {
    this.fetchTransporters()
    this.resetSearch()
  },

  methods:{
    blueprint,
    ...mapActions({
      fetchTransporters: 'transporters/fetchTransporters',
      fetchOrdersWithoutRoutes: 'routes/fetchOrdersWithoutRoutes',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    async search() {
      const params = {
        obj: {
          query: this.filters.query,
          store_id: this.filters.store_id,
          transporter_id: this.filters.transportersOrigin,
          'filter[status][store_status_type_id]': [
            {id: '__const:storeStatusType__pendente', name: 'Pendente' },
            {id: '__const:storeStatusType__standby', name: 'Standby'},
            {id: '__const:storeStatusType__andamento', name: 'Andamento'},
            {id: '__const:storeStatusType__emTransferencia', name: 'Em Transferência'},
          ],
        },
        paginate: this.paginate,
        page: this.page
      }

      await this.fetchOrdersWithoutRoutes(params)
        .then(() => {
          let ordersReturned = this.transferTable.data.content.map(order => order.id)
          this.form.orders = this.form.orders.filter(order => ordersReturned.includes(order.id))
          this.checkIfOrdersExist()
          this.setPagination(this.transferTable)
          this.setLoading(false)
        })
    },

    resetSearch() {
      this.form.orders = []
      this.form.transporterDestiny = null
      this.form.type = null
      this.clearPagination().then(() => this.search())
    },

    async onSubmit(data) {
      if(this.ordersWithTransporterDestinyError.length) {
        if(this.form.orders.length > this.ordersWithTransporterDestinyError.length) {
          this.submitOrdersWithTransporterDestinyError()
        }
      } else {
        await this.submitFunction(data)
      }
    },

    async submitFunction(data, filterTransporterDestinyError = false) {
      const transporter_id = data.transporterDestiny.id

      let orders = data.orders
      if(filterTransporterDestinyError){
        orders = orders.filter(order =>
          !this.ordersWithTransporterDestinyError.some(orderError => orderError === order.order_number)
        )
      }
      orders = orders.map(({ id }) => id)

      const body = { orders, transporter_id }

      this.setLoading(true)
      await transporters.changeResponsibility(body, data.type.value, () => {
        this.showSuccessDialog = true
      }, (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    submitOrdersWithTransporterDestinyError() {
      if (this.ordersWithTransporterDestinyError.length > 1) {
        this.ordersWithTransporterDestinyErrorText = `Os pedidos abaixo já pertencem à transportadora destino e não serão considerados ${this.typeName.optionB}. Deseja continuar?`
      } else {
        this.ordersWithTransporterDestinyErrorText = `O pedido abaixo já pertence à transportadora destino e não será considerado ${this.typeName.optionB}. Deseja continuar?`
      }
      this.showOrdersWithTransporterDestinyError = true
    },

    updateTags(){
      this.allTags = [...this.filters.query]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id.toLowerCase() !== id.toLowerCase())
      this.filters.query = this.filters.query.filter((tag) => tag.name.toLowerCase() !== name.toLowerCase())
    },

    clearAllTags(){
      this.allTags = []
      this.filters.query = []
    },

    checkIfOrdersExist() {
      let query = [...new Set(this.filters.query.map(q => q.name))]
      let ordersNumber = this.transferTable.data.content.map(order => order.order_number != null ? order.order_number.toLowerCase() : order.order_number)
      let ordersInvoiceNumber = this.transferTable.data.content.map(order => order.invoice_number != null ? order.invoice_number.toLowerCase() : order.invoice_number)

      this.missingOrders = query.filter(f => !ordersNumber.includes(f.toLowerCase()) && !ordersInvoiceNumber.includes(f.toLowerCase()))
      if (this.missingOrders.length > 0) {
        if (this.missingOrders.length > 1) {
          this.missingOrdersText = 'Alguns pedidos de sua pesquisa não foram encontrados no sistema com base nos filtros que você escolheu ou não estão com o status apto para serem redespachados ou transferidos entre transportadoras:'
        } else {
          this.missingOrdersText = 'Um pedido da sua pesquisa não foi encontrado no sistema com base nos filtros que você escolheu ou não está com o status apto para ser redespachado ou transferido entre transportadoras:'
        }
        this.showMissingOrders = true
      }
    },

    removeMissingOrders() {
      let m = this.missingOrders
      for (let i = 0; i < m.length; i++) {
        this.removeTag(m[i], m[i])
        this.$refs.query.removeTag(m[i])
      }
      this.showMissingOrders = false
    },

    async hideOrdersWithTransporterDestinyError() {
      this.showOrdersWithTransporterDestinyError = false
      await this.submitFunction(this.form, true)
    },

    confirmSuccess(){
      this.showSuccessDialog = false
      this.resetSearch()
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/.TransporterTransfer {
  &__content,
  &__orders {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__ordersContent {
    grid-area: main;
  }

  &__form {
    width: 100%;
    position: relative;
    grid-template-areas: 'type type type transporterDestiny transporterDestiny transporterDestiny . . . . . confirm confirm confirm confirm';
    align-items: flex-end;
  }

  &__dialog{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-modal-bg;

    &--hide{
      display: none;
    }

    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }

  }

   &__ordersContent {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas:
      "filter"
      "table";
    @media #{$mobile-view} {
      grid-template-columns: 1fr;
      grid-template-areas:
        "filter"
        "table";
    }
  }

  &__table {
    grid-area: table;
    display: flex;
    flex-direction: column;

    &--dataTable {
      @media #{$mobile-view} {
        order: 1;
        position: relative;
      }
    }

    &--pagination {
      @media #{$mobile-view} {
        order: 2;
        position: relative;
      }
    }

    &--confirm {
      position: sticky;
      bottom: 0;
      padding: 15px 0 30px;
      background: white;
      z-index: $z-active;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media #{$mobile-view} {
        padding: 0;
        margin-top: 0;
        margin-bottom: 20px;
        order: 0;
        position: relative;
      }

      p {
        font-size: $font-size-3xsmall;
        margin: 0 0 15px 0;
        padding: 0;
      }
      &:before {
        content: '';
        width: 300vw;
        display: block;
        position: absolute;
        height: 50px;
        bottom: 100%;
        left: -100vw;
        @media #{$mobile-view} {
          display: none;
        }
        pointer-events: none;
        background: linear-gradient(
          to top,
          rgba($color-neutral-stronger, 0.1) 0%,
          rgba($color-neutral-stronger, 0) 50%
        );
      }
      @media #{$mobile-view} {
        flex-direction: column;
        width: 100%;
          z-index: 4001
      }
    }

    &--information {
      width: 100%;
      display: flex;
      gap: 5px;

      &--error {
        color: $color-alert-base;
      }
    }

  }
}
</style>