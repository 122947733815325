export default ({ transportersDestiny, disabledFields }) => {

  const type = [
    {
      id: 'type',
      model: 'type',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo de operação',
        label: 'Tipo de operação',
        placeholder: 'Selecione o tipo de operação',
        invalidLabel: 'Selecione um tipo de operação',
        selectOptions: [
          {id: 0, name: 'Redespacho Agenciadora', value: 'redespacho_agenciadora'},
          {id: 1, name: 'Transferência Interestadual', value: 'transferencia_interestadual'}
        ],
        disabled: disabledFields.type
      },
      events: {
        input: 'clearTransporterDestiny'
      }
    },
  ]

  const transporter = [
    {
      id: 'transporterDestiny',
      model: 'transporterDestiny',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Transportadora Destino',
        label: 'Transportadora Destino',
        placeholder: 'Selecione a transportadora',
        invalidLabel: 'Vincule uma transportadora',
        selectOptions: transportersDestiny,
        disabled: disabledFields.transporterDestiny
      },
    },
  ]

  const actions = [
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Confirmar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true,
        disabled: disabledFields.save
      }
    }
  ]

  return [
    ...type,
    ...transporter,
    ...actions
  ]

}