const onCodeDefault = function (modelSliced) {
  this.onCodeBipping(modelSliced)
}

const onCodeSliced = function (modelSliced) {
  this.onCodeBipping(modelSliced)
}

const onCodeBipping = function (modelSliced) {
  if (modelSliced === '') {
    return
  }

  //Check if its bipping, remove previous barcode full
  if (this.itsBipping) {
    this.filters.query = this.filters.query.filter(
      (el) => el.name !== modelSliced
    )
  }

  if (this.filters.query.filter((el) => el.id === modelSliced).length === 0) {
    this.$refs.query.addTag(modelSliced)
    this.updateTags()
    this.resetSearch()
  } else {
    setTimeout(() => {
      this.barcodeBeepingCheck(false)
      this.$toast.warning(`O código '${modelSliced}' já foi inserido.`, {
        toastClassName: 'big'
      })
    }, 100)
  }
}

const callbackBarcodeScanned = function () {
  this.updateTags()
}

const callbackInputBarcodeScanned = function () {
  this.updateTags()
  this.resetSearch()
}

const BarCodeScanner = () => {
  return {
    onCodeDefault,
    onCodeSliced,
    onCodeBipping,
    callbackBarcodeScanned,
    callbackInputBarcodeScanned,
  }
}

export {
  BarCodeScanner
}