const beepingSoundMixin = {
  data() {
    return {
      audioBeep: new AudioContext(),
      hasValueOnInput: false,
    }
  },
  methods: {
    //Beeping
    barcodeBeepingCheck(val) {
      const beepingSoundIcon = localStorage.getItem('beeping-settings') ? localStorage.getItem('beeping-settings') === 'true' : true

      if (beepingSoundIcon) {
        if (val) {
          this.barcodeBeeping(100, 520, 200)
        } else {
          this.barcodeBeeping(999, 220, 300)
  
          // enable vibration support
          navigator.vibrate =
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
  
          if (navigator.vibrate) {
            navigator.vibrate(300)
          }
        } 
      }
  
      this.hasValueOnInput = false
    },

    barcodeBeeping(vol, freq, duration) {
      let v = this.audioBeep.createOscillator()
      let u = this.audioBeep.createGain()
      v.connect(u)
      v.frequency.value = freq
      v.type = 'square'
      u.connect(this.audioBeep.destination)
      u.gain.value = vol * 0.01
      v.start(this.audioBeep.currentTime)
      v.stop(this.audioBeep.currentTime + duration * 0.001)
    },

    checkBeepingOnInput() {
      this.hasValueOnInput = true
    }
  },
}

export default beepingSoundMixin
